<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="138" height="138" viewBox="0 0 138 138">
  </svg>
</template>

<script>
export default {
  name: 'LogoNoText',
  props: {
    color: {
      type: String,
      required: true
    },
    width: {
      required: false,
      default: ''
    },
    height: {
      required: false,
      default: ''
    }
  }
}
</script>

