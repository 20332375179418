<template>
  <v-img contain src="~@/assets/custom/images/header-logo.png"/>
</template>

<script>
export default {
  name: 'MainLogo',
  props: {
    fillColor: {
      type: String,
    }
  }
}
</script>
